import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user;

  constructor(public auth: AngularFireAuth, private router: Router) {
    this.auth.onAuthStateChanged((user => {
      this.user = user;
      if(user) {
        console.log(user);
        this.router.navigate(['/', 'admin']);
      } else {
        console.log("There is no user.");
        this.router.navigate(['/', 'login']);
      }
    }));
  }

  login(email, password) {
    this.auth.signInWithEmailAndPassword(email, password).catch(function(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      if(errorCode == 'auth/wrong-password') {
        console.log("Wrong Password");
      } else {
        console.log(errorMessage);
      }
    });
  }

  logout() {
    this.auth.signOut();
  }

  async getUser() {
    return this.user;
  }
}
