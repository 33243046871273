import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';

import { RouterModule, Routes } from '@angular/router'

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { LoginComponent } from './components/login/login.component';
import { AdminPageComponent } from './components/admin-page/admin-page.component';
import { BottomBarComponent } from './graphics/bottom-bar/bottom-bar.component';
import { TopBarComponent } from './graphics/top-bar/top-bar.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'admin', component: AdminPageComponent },
  { path: 'graphics/bottom-bar', component: BottomBarComponent},
  { path: 'graphics/top-bar', component: TopBarComponent}
]

//Firebase Credentials
const config = {
  apiKey: "AIzaSyAIJmnMg-b1H3SAbvT3-SslxsDb0gmoIXY",
  authDomain: "everyday-astronaut-graphics.firebaseapp.com",
  projectId: "everyday-astronaut-graphics",
  storageBucket: "everyday-astronaut-graphics.appspot.com",
  messagingSenderId: "95411528617",
  appId: "1:95411528617:web:61b3261831fb7a32151ab8",
  measurementId: "G-Y8ZYKHWM8S"
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminPageComponent,
    BottomBarComponent,
    TopBarComponent
  ],
  imports: [
    BrowserModule, 
    AngularFireModule.initializeApp(config), 
    AngularFirestoreModule, 
    AngularFireStorageModule, 
    AngularFireAuthModule,
    FormsModule,
    RouterModule.forRoot(routes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
