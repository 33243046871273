<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
        <a class="navbar-brand">Everyday Astronaut</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Admin Panel</a>
            </li>
        </ul>
        <span class="navbar-text">
            <button type="button" class="btn btn-primary" (click)="logout();">Sign Out</button>
        </span>
        </div>
    </div>
</nav>

<div class="row">
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Bottom Bar Events</h5>
                <div *ngFor="let event of bottombar.eventProgress; index as index">
                    <label for="basic-url" class="form-label"><strong>Event {{index+1}}</strong></label>
                    <div class="row">
                        <div class="col-9">
                            <input [(ngModel)]="bottombar.eventNames[index]" #ctrl="ngModel" type="text" class="form-control">
                        </div>
                        <div class="col-3">
                            <input [(ngModel)]="bottombar.eventProgress[index]" type="text" class="form-control">
                        </div>
                    </div>        
                </div>
                <button (click)="updateEvents()" class="btn btn-outline-warning" type="button" id="button-addon2">Update</button>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">General</h5>
                <label for="basic-url" class="form-label">Title</label>
                <div class="input-group mb-3">
                    <input [(ngModel)]="bottombar.title" type="text" class="form-control">
                    <button (click)="updateTitle()" class="btn btn-outline-warning" type="button" id="button-addon2">Update</button>
                </div>
                <div class="alert alert-secondary" role="alert">
                    <strong>Currently Highlighted: </strong>{{bottombar.eventNames[bottombar.currentEvent]}}
                  </div>
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button (click)="backwards()" type="button" class="btn btn-danger">Backwards</button>
                    <button (click)="forwards()" class="btn btn-success">Forwards</button>
                </div>
            </div>
            <hr/>
        </div>
    </div>
</div>