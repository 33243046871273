import { Component, Input, OnInit } from '@angular/core';
import { BottomBar } from 'src/app/models/bottombar';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css']
})
export class AdminPageComponent implements OnInit {

  bottombar: BottomBar = {
    title: 'Loading...',
    eventNames: [''],
    eventProgress: [],
    currentEvent: 0
  }

  test;

  constructor(public authService: AuthService, public firestoreService: FirestoreService) {
    this.firestoreService.getBottomBar().subscribe(bottombar => {
      this.bottombar = bottombar;
    });
   }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
  }

  updateTitle() {
    this.firestoreService.updateTitle(this.bottombar.title)
  }

  updateEvents() {
    this.firestoreService.updateEvents(this.bottombar.eventNames, this.bottombar.eventProgress);
  }

  forwards() {
    this.firestoreService.updateCurrentEvent(this.bottombar.currentEvent + 1);
  }

  backwards() {
    this.firestoreService.updateCurrentEvent(this.bottombar.currentEvent - 1);
  }

}
