
  <div class="row">
    <div class="col-4"></div>
    <div class="col-12 col-md-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Welcome</h5>
    
          <div class="input-group mb-3">
            <input [(ngModel)]="email" type="text" class="form-control" placeholder="Email" aria-label="Email" aria-describedby="basic-addon1">
          </div>
          
          <div class="input-group mb-3">
            <input [(ngModel)]="password" type="password" class="form-control" placeholder="Password" aria-label="Password" aria-describedby="basic-addon2">
          </div>
    
          <button type="button" class="btn btn-primary" (click)="login();">Sign In</button>
        </div>
      </div>
    </div>
  </div>