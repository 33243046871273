import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BottomBar } from 'src/app/models/bottombar';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.css']
})
export class BottomBarComponent implements OnInit {

  bottombar: BottomBar = {
    title: 'Loading...',
    eventNames: [''],
    eventProgress: [],
    currentEvent: 0,
    currentBarLocation: 0
  }

  constructor(public firestoreService: FirestoreService, private sanitizer: DomSanitizer) {
    this.firestoreService.getBottomBar().subscribe(bottombar => {
      this.bottombar = bottombar;
      console.log(bottombar.eventNames);
    });
  }

  ngOnInit(): void {
  }

}
