import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BottomBar } from '../models/bottombar';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  graphicsCollection: AngularFirestoreCollection<BottomBar>;
  bottombarDocument: AngularFirestoreDocument<BottomBar>;
  bottombar: Observable<BottomBar>;

  constructor(public firestore: AngularFirestore) {
    this.bottombarDocument = this.firestore.collection('graphics').doc('bottom-bar');
    this.bottombar = this.bottombarDocument.snapshotChanges().pipe(map(changes => {
      const data = changes.payload.data() as BottomBar;
      return data;
    }));
  }

  getBottomBar() {
    return this.bottombar;
  }

  updateTitle(title) {
    this.bottombarDocument = this.firestore.collection('graphics').doc('bottom-bar');
    this.bottombarDocument.update({
      title: title
    });
  }

  updateEvents(names, progress) {
    this.bottombarDocument = this.firestore.collection('graphics').doc('bottom-bar');
    this.bottombarDocument.update({
      eventNames: names,
      eventProgress: progress
    });
  }

  updateCurrentEvent(value) {
    this.bottombarDocument = this.firestore.collection('graphics').doc('bottom-bar');
    this.bottombarDocument.update({
      currentEvent: value
    });
  }

}
