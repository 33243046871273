<div>
    <p id="title">{{bottombar.title}}</p>
    <div  id="events">
        <span *ngIf="bottombar.currentEvent >= 0">{{bottombar.eventNames[0]}}</span>
        <span *ngIf="bottombar.currentEvent >= 1">{{bottombar.eventNames[1]}}</span>
        <span *ngIf="bottombar.currentEvent >= 2">{{bottombar.eventNames[2]}}</span>
        <span *ngIf="bottombar.currentEvent >= 3">{{bottombar.eventNames[3]}}</span>
        <span *ngIf="bottombar.currentEvent >= 4">{{bottombar.eventNames[4]}}</span>
        <span *ngIf="bottombar.currentEvent >= 5">{{bottombar.eventNames[5]}}</span>
        <span *ngIf="bottombar.currentEvent < 0" style="color:gray">{{bottombar.eventNames[0]}}</span>
        <span *ngIf="bottombar.currentEvent < 1" style="color:gray">{{bottombar.eventNames[1]}}</span>
        <span *ngIf="bottombar.currentEvent < 2" style="color:gray">{{bottombar.eventNames[2]}}</span>
        <span *ngIf="bottombar.currentEvent < 3" style="color:gray">{{bottombar.eventNames[3]}}</span>
        <span *ngIf="bottombar.currentEvent < 4" style="color:gray">{{bottombar.eventNames[4]}}</span>
        <span *ngIf="bottombar.currentEvent < 5" style="color:gray">{{bottombar.eventNames[5]}}</span>
    </div>
    <div *ngIf="bottombar.currentEvent >=0" id="progressbar">
        <div [style.width]="bottombar.eventProgress[bottombar.currentEvent] + 'px'"></div>
    </div>
    <div *ngIf="bottombar.currentEvent < 0" id="progressbar2">
        <div></div>
    </div>
    <img id="logo" src="../../../assets/EDA Logo White.png">
    <svg id="background" data-name="Bottom Bar Background" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 121.86"><defs><style>.cls-1{fill:#1c1c1c;}</style></defs><polygon class="cls-1" points="0 0 410.84 0 422.91 21.47 1920 21.47 1920 121.86 0 121.86 0 0"/></svg>
</div>